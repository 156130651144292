<template>
  <div>
    <CCardBody class="c-card-body">      
      <CDataTable 
        :items="dictionaries"
        :fields="fields"
        hover
        border
        sorter
        :tableFilter = "{label: 'Поиск', placeholder: 'введите название...'}"
      >
        <template  #name="{ item }">
          <td class="td">
            <router-link :to="`/dictionaries/${item.namespace}`">
              {{ item.name }}
            </router-link>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </div>
</template>

<style>

.test {
    background-color: red;
}

.c-card-body {
    background-color: #fefefe ;
}

table {
  font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
  font-size: 14px;
  background: white;
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

th {
  font-weight: normal;
  color: #039;
  border-bottom: 2px dashed #6678b1;
  padding: 10px 8px;
}

.td {
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}

tr:hover .td {
  background: #e8edff;
}

.td {
  border-bottom: 0.5px dashed #ccc;
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}

th:nth-child(-n + 3),
.td:nth-child(-n + 3) {
  text-align: left;
}

.item-right {
    text-align: right;
}
</style>

<script>
const fields = [
  {
    key: "name",
    label: "Справочник",
  }
];

const dictionaries = [
{
    namespace: "dictionary-colors",
    name: "Цвета"
  },
{
    namespace: "dictionary-coverings",
    name: "Покрытия"
  },
  {
    namespace: "dictionary-mount-types",
    name: "Тип крепления"
  },
  {
    namespace: "dictionary-sputterings",
    name: "Напыление"
  }
];

export default {
  name: "Dictionaries",
  data() {
    return {
      fields,
      dictionaries
    };
  }
};
</script>
